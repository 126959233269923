import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"label":"Gas Station","items":_vm.gasStations,"item-text":"name","return-object":""},model:{value:(_vm.gasStation),callback:function ($$v) {_vm.gasStation=$$v},expression:"gasStation"}})],1)],1),_c(VCardText,[_c(VTabs,{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.isSysAdmin() || _vm.isAdmin())?_c(VTab,{key:"zHourTab"},[_vm._v(" Z Hour ")]):_vm._e(),_c(VTab,{key:"workingHoursTab"},[_vm._v(" Working Hours ")]),_c(VTab,{key:"cooksTab"},[_vm._v(" Cooks ")]),_c(VTab,{key:"bsoTab"},[_vm._v(" BSO Products ")]),_c(VTab,{key:"bsoIngredientTab"},[_vm._v(" BSO Ingredients ")]),_c(VTab,{key:"equipmentTab"},[_vm._v(" Equipment ")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:"zHourTab"},[(_vm.tab === 0)?_c('z-hour-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1),_c(VTabItem,{key:"workingHoursTab"},[(_vm.tab === 1)?_c('working-hours-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1),_c(VTabItem,{key:"cooksTab"},[(_vm.tab === 2)?_c('cooks-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1),_c(VTabItem,{key:"bsoTab"},[(_vm.tab === 3)?_c('bso-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1),_c(VTabItem,{key:"bsoIngredientTab"},[(_vm.tab === 4)?_c('bso-ingredient-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1),_c(VTabItem,{key:"equipmentTab"},[(_vm.tab === 5)?_c('equipment-tab',{attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }