import { render, staticRenderFns } from "./CooksTab.vue?vue&type=template&id=ca1d507c"
import script from "./CooksTab.vue?vue&type=script&lang=ts"
export * from "./CooksTab.vue?vue&type=script&lang=ts"
import style0 from "./CooksTab.vue?vue&type=style&index=0&id=ca1d507c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports