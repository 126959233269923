
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStationCooks, IStationWorkingHours } from "@common/station";
import _ from "lodash";

export default Vue.extend({
	name: "CooksTab",
	props: {
		stationId: String,
	},
	data () {
		const hours: {
			day: number;
			startingHour: number;
			endingHour: number;
			inventoryHour: number;
		}[] = [];

		const cookNumbers: {
			day: number;
			hour: number;
			cooks: number;
		}[] = [];

		const snackbar = {
			show: false,
			text: "",
			color: "primary",
		};

		const wholeTable: {
			count?: number;
			empty?: boolean;
			display?: string;
			currentHour?: number;
		}[][] = [];

		return {
			hours,
			cookNumbers,
			snackbar,
			wholeTable,
			loading: false
		};
	},
	created () {
		this.fetchData();
	},
	methods: {
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/working-hours/${this.stationId}`,
				};

				const res: AxiosResponse<IServerRES<IStationWorkingHours[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.hours = res.data.payload.map((item: IStationWorkingHours) => {
						return {
							day: item.day, // moment().isoWeekday(item.day).format("dddd"),
							startingHour: item.startingHour,
							endingHour: item.endingHour,
							inventoryHour: item.inventoryHour,
						};
					});
				}
			} catch (err) {
				console.error(err);
			}

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/cooks/${this.stationId}`,
				};

				const res: AxiosResponse<IServerRES<IStationCooks[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.cookNumbers = res.data.payload.map((item: IStationCooks) => {
						return {
							day: item.day,
							hour: item.hour,
							cooks: item.cooks
						};
					});
				}
			} catch (err) {
				console.error(err);
			}

			this.dbDataToTable();

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		dbDataToTable () {
			const minHour = _.min(_.map(this.hours, "startingHour"));
			const maxHour = _.max(_.map(this.hours, "endingHour"));

			if (minHour === undefined || maxHour === undefined) {
				return [];
			}

			const wholeTable = [];

			for (let currentHour = minHour; currentHour < maxHour; currentHour++) {
				const row = [];
				row.push({
					display: (_.padStart(currentHour.toString(), 2, "0") + ":00") + " - " + (_.padStart(((currentHour + 1) % 24).toString(), 2, "0") + ":00")
				});
				for (let currentDay = 0; currentDay < 7; currentDay++) {
					const hoursObject = _.find(this.hours, hour => hour.day === currentDay);
					if (hoursObject && currentHour >= hoursObject.startingHour && currentHour < hoursObject.endingHour) {
						const found = _.find(this.cookNumbers, cookNumber => (
							cookNumber.hour === currentHour && cookNumber.day === currentDay
						));
						if (found) {
							row.push({
								count: found.cooks,
								currentHour
							});
						} else {
							row.push({
								empty: true
							});
						}
					} else {
						row.push({
							empty: true
						});
					}
				}
				wholeTable.push(row);
			}
			this.wholeTable = wholeTable;
		},
		tableDataToDb () {
			const toSend = [];

			for (let hourIndex = 0; hourIndex < this.wholeTable.length; hourIndex++) {
				const tempElement = this.wholeTable[hourIndex];

				for (let dayIndex = 1; dayIndex < tempElement.length; dayIndex++) {
					const element = tempElement[dayIndex];

					if (element.currentHour && element.count !== undefined) {
						toSend.push({
							stationId: this.stationId,
							day: dayIndex - 1,
							hour: element.currentHour,
							cooks: _.parseInt(element.count.toString().replace(/[^0-9]/g, ""), 10)
						});
					}
				}
			}

			return toSend;
		},
		async save () {
			this.loading = true;

			const data: IStationCooks[] = this.tableDataToDb();

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/cooks`,
					data
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "Number of cooks saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save number of cooks!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save number of cooks!",
					color: "error",
				};
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
	},
	computed: {
		headers () {
			return [
				{ text: "Hours", align: "start", minWidth: "100" },
				{ text: "Monday", align: "start", minWidth: "100" },
				{ text: "Tuesday", align: "start", minWidth: "100" },
				{ text: "Wednesday", align: "start", minWidth: "100" },
				{ text: "Thursday", align: "start", minWidth: "100" },
				{ text: "Friday", align: "start", minWidth: "100" },
				{ text: "Saturday", align: "start", minWidth: "100" },
				{ text: "Sunday", align: "start", minWidth: "100" }
			];
		},
	},
	watch: {
		stationId: function () {
			this.fetchData();
		}
	}
});
