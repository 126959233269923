export interface IStation {
    id: string;
    name: string;
}

export interface ITablet {
    id?: string;
    stationId: string;
    stationName?: string;
    tabletToken: string;
    tabletPin?: string;
    verified: boolean;
    lastSeen: Date | null;
}

export interface IStationOperator {
    id: string;
    stationId: string;
    username: string;
    fullName: string;
    password?: string;
}

export interface IStationZHour {
    day: number;
    stationId: string;
    zHour: number;
}

export interface IStationEquipment {
    stationId: string;
    ingredientId: string;
    capacityPerBatch: number;
    batchesPerHour: number;
}

export const DEFAULT_ZHOUR = 12;

export interface IStationWorkingHours {
    day: number;
    stationId: string;
    startingHour: number;
    endingHour: number;
    inventoryHour: number;
    multiplicationIndex: number;
}

export interface IStationCooks {
    stationId: string;
    day: number;
    hour: number;
    cooks: number;
}

export interface IStationBuffer {
    stationId: string;
    productId?: string;
    ingredientId?: string;
    day: number;
    buffer: number[];
}

export interface IStationBufferDB {
    stationId: string;
    productId?: string;
    ingredientId?: string;
    day: number;
    hour0: number;
    hour1: number;
    hour2: number;
    hour3: number;
    hour4: number;
    hour5: number;
    hour6: number;
    hour7: number;
    hour8: number;
    hour9: number;
    hour10: number;
    hour11: number;
    hour12: number;
    hour13: number;
    hour14: number;
    hour15: number;
    hour16: number;
    hour17: number;
    hour18: number;
    hour19: number;
    hour20: number;
    hour21: number;
    hour22: number;
    hour23: number;
}

export const DEFAULT_STARTING_HOUR = 7;
export const DEFAULT_ENDING_HOUR = 18;
export const DEFAULT_INVENTORY_HOUR = 6;
export const DEFAULT_MULTIPLICATION_INDEX = 1;

export function isStationWorkingHour (obj: any): boolean {
    const isDay = obj.day !== undefined && obj.day !== null;
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isStartingHour = obj.startingHour !== undefined && obj.startingHour !== null;
    const isEndingHour = obj.endingHour !== undefined && obj.endingHour !== null;
    const isInventoryHour = obj.inventoryHour !== undefined && obj.inventoryHour !== null;
    const isMultiplicationIndex = obj.multiplicationIndex !== undefined && obj.multiplicationIndex !== null;

    return isDay && isStationId && isStartingHour && isEndingHour && isInventoryHour && isMultiplicationIndex;
}

export function isStationCooks (obj: any): boolean {
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isDay = obj.day !== undefined && obj.day !== null;
    const isHour = obj.hour !== undefined && obj.hour !== null;
    const isCooks = obj.cooks !== undefined && obj.cooks !== null;

    return isStationId && isDay && isHour && isCooks;
}

export function isStationBuffer (obj: any): boolean {
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isIngredientId = obj.ingredientId !== undefined && obj.ingredientId !== null;
    const isDay = obj.day !== undefined && obj.day !== null;
    const isBuffer = obj.buffer !== undefined && obj.buffer !== null;

    return isStationId && (isProductId || isIngredientId) && isDay && isBuffer;
}

export function isStationEquipment (obj: any): boolean {
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isIngredientId = obj.ingredientId !== undefined && obj.ingredientId !== null;
    const isCapacityPerBatch = obj.capacityPerBatch !== undefined && obj.capacityPerBatch !== null;
    const isBatchesPerHour = obj.batchesPerHour !== undefined && obj.batchesPerHour !== null;

    return isStationId && isIngredientId && isCapacityPerBatch && isBatchesPerHour;
}

export const EMPTY_TABLET: ITablet = {
    id: "",
    stationId: "",
    stationName: "",
    tabletToken: "",
    tabletPin: "",
    verified: false,
    lastSeen: null
}

export const EMPTY_OPERATOR: IStationOperator = {
    id:  "",
    stationId: "",
    username: "",
    fullName: "",
    password: ""
}

export interface ITabletRegisterRes {
    succeeded: boolean;
}

export interface ITabletDeleteRes {
    succeeded: boolean;
}

export interface IStationOperatorUpdateRes {
    succeeded: boolean;
    operator: IStationOperator;
}

export interface IStationOperatorDeleteRes {
    succeeded: boolean;
}

export interface IStationOperatorsGetAllReq {
    gasStations: IStation[]
}

export interface IStationBufferAddRes {
    succeeded: boolean;
}
