
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { DEFAULT_ZHOUR, IStationZHour } from "@common/station";
import moment from "moment-timezone";

export default Vue.extend({
	name: "ZHourTab",
	props: {
		stationId: String,
	},
	data () {
		const hours: {
			day: string;
			hour: number;
		}[] = [];

		return {
			hours,
			allAvailableHours: Array.from({ length: 24 }, (_, i) => i),
			loading: false
		};
	},
	created () {
		this.fetchData();
	},
	methods: {
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/zhour/${this.stationId}`,
				};

				const res: AxiosResponse<IServerRES<IStationZHour[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.hours = res.data.payload.map((item: IStationZHour) => {
						return {
							day: moment().isoWeekday(item.day).format("dddd"),
							hour: item.zHour,
						};
					});
				}
			} catch (err) {
				console.error(err);
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
	},
	computed: {
		headers () {
			return [
				{ text: "Day", value: "day", align: "start", minWidth: "100" },
				{ text: "Z Hour", value: "hour", align: "start", minWidth: "100" },
			];
		},
	},
	watch: {
		stationId: function () {
			this.fetchData();
		},
		hours: {
			handler: function () {
				if (this.hours.length !== 7) {
					this.hours = [
						{ day: "Monday", hour: DEFAULT_ZHOUR },
						{ day: "Tuesday", hour: DEFAULT_ZHOUR },
						{ day: "Wednesday", hour: DEFAULT_ZHOUR },
						{ day: "Thursday", hour: DEFAULT_ZHOUR },
						{ day: "Friday", hour: DEFAULT_ZHOUR },
						{ day: "Saturday", hour: DEFAULT_ZHOUR },
						{ day: "Sunday", hour: DEFAULT_ZHOUR },
					];
				}
			},
			deep: true,
		}
	}
});
