import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[(!_vm.loading)?[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VDataTable,{staticClass:"pb-1",attrs:{"headers":_vm.headers,"items":_vm.wholeTable,"disable-pagination":"","hide-default-footer":true,"disable-sort":true},scopedSlots:_vm._u([{key:"header.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")]}},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item[0].display))]),_vm._l(([1,2,3,4,5,6,7]),function(index){return _c('td',{key:index,style:({ minWidth: '100px', maxWidth: '150px', tableLayout: 'fixed' })},[(!item[index].empty)?_c(VTextField,{staticClass:"centered-input",attrs:{"hide-details":"","single-line":"","outlined":""},model:{value:(item[index].count),callback:function ($$v) {_vm.$set(item[index], "count", $$v)},expression:"item[index].count"}}):_vm._e()],1)})],2)]}}],null,false,1086476888)})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.save}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("save")]),_vm._v("Save ")],1)],1)],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("clear")])],1)]}}],null,false,3501873549),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])]:[_c(VSkeletonLoader,{attrs:{"type":"\n\t\t\t\t\t\tparagraph,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }