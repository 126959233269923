
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import * as XLSX from "xlsx";

import { IStation } from "@common/station";
import { IIngredient } from "@common/ingredient";
import { ITraceability, ITraceabilityStock } from "@common/traceability";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

interface TraceabilityExcel {
    "Cod Articol": string;
    "Denumire Articol": string;
    "UM": string;
    "Data - Ora": string;
    "Tip tranzactie": string;
    "Intrare gestiune principala"?: number;
    "Iesire gestiune principala"?: number;
    "Tip iesire 1"?: string;
    "Nr lot 1": string;
    "Data Expirare 1": string;
    "Intrare gestiune productie"?: number;
    "Iesire gestiune productie"?: number;
    "Tip iesire 2"?: string;
    "Nr lot 2": string;
    "Data expirare 2": string;
}

interface TraceabilityStockExcel {
    "Cod Articol": string;
    "Denumire Articol": string;
    "UM": string;
    "Stoc curent - gestiune principala": number;
    "Stoc curent - gestiune productie": number;
};

export default Vue.extend({
	name: "Traceability",
	data: () => {
		return {
			filters: {
				ingredients: [] as string[],
				gasStation: {} as IStation,
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
			},
			gasStations: [] as IStation[],
			ingredients: [] as IIngredient[],
			get role (): string {
				return localStorage.getItem("role") || "";
			},
			autoCompleteChipsLimit: 10,
			menus: {
				dateRange: false,
				timeRange: false
			},
			selectedRange: 3,
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Stations");
		await this.fetchGasStations();
		await this.fetchIngredients();
	},
	computed: {
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		ingredientSelectItems () {
			const ingredientSelectItems: {
				text: string;
				value: string;
			}[] = [];

			for (const ingredient of this.store.getters.ingredients) {
				const item = {
					text: `(${ingredient.id}) ${ingredient.name}`,
					value: ingredient.id
				};

				if (_.findIndex(ingredientSelectItems, item) === -1) {
					ingredientSelectItems.push(item);
				}
			}

			return ingredientSelectItems;
		},
		icon () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) return "mdi-close-box";
			if (this.filters.ingredients.length !== this.ingredientSelectItems.length && this.filters.ingredients.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
	},
	methods: {
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		isSysAdmin (): boolean {
			return this.role === "sys-admin";
		},
		isAdmin (): boolean {
			return this.role === "admin";
		},
		isGasAdmin (): boolean {
			return this.role === "gas-admin";
		},
		async fetchGasStations () {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/all`,
				};
				const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.gasStations = res.data.payload.map((item) => {
						item.name = `(${item.id}) ${item.name}`;

						return item;
					});
					if (this.gasStations.length) {
						this.filters.gasStation = this.gasStations[0];
					}
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchIngredients () {
			if (this.store.getters.ingredients.length === 0) {
				await this.store.dispatch.fetchOnlyUsedIngredients();
			}

			this.filters.ingredients = [this.ingredientSelectItems[0].value];
		},
		toggleSelectAllIngredients () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) {
				this.filters.ingredients = [];
			} else {
				for (const item of this.ingredientSelectItems) {
					if (this.filters.ingredients.indexOf(item.value) === -1) {
						this.filters.ingredients.push(item.value);
					}
				}
			}
		},
		async fetchData () {
			const wb = XLSX.utils.book_new();

			{
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/traceability/${this.filters.gasStation.id}`,
					data: {
						ingredients: this.filters.ingredients,
						dateStart: this.filters.dateRange[0],
						dateEnd: moment(this.filters.dateRange[1]).add(1, "day").format("YYYY-MM-DD")
					},
				};

				const result: AxiosResponse<IServerRES<ITraceability[]>> = await axios(options);

				if (result.data.err === ServerError.NO_ERROR) {
					const excelData: TraceabilityExcel[] = [];
					for (const transaction of result.data.payload) {
						excelData.push({
							"Cod Articol": transaction.ingredientId,
							"Denumire Articol": transaction.name,
							UM: transaction.um,
							"Data - Ora": moment(transaction.dateHourTransaction).format("DD/MM/YYYY HH:mm:ss"),
							"Tip tranzactie": transaction.transactionType,
							"Intrare gestiune principala": transaction.principalEntryQty,
							"Iesire gestiune principala": transaction.principalExitQty,
							"Tip iesire 1": transaction.principalTypeExit,
							"Nr lot 1": transaction.principalBatch,
							"Data Expirare 1": moment(transaction.principalExpiryDate).format("DD/MM/YYYY HH:mm:ss"),
							"Intrare gestiune productie": transaction.productionEntryQty,
							"Iesire gestiune productie": transaction.productionExitQty,
							"Tip iesire 2": transaction.productionTypeExit,
							"Nr lot 2": transaction.productionBatch,
							"Data expirare 2": transaction.productionExpiryDate === null ? "-" : moment(transaction.productionExpiryDate).format("DD/MM/YYYY HH:mm:ss")
						});
					}
					const ws = XLSX.utils.json_to_sheet(excelData);
					XLSX.utils.book_append_sheet(wb, ws, "Traceability");
				} else {
					console.error(result.data.err);
				}
			}

			{
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/traceability/stock/${this.filters.gasStation.id}`
				};

				const result: AxiosResponse<IServerRES<ITraceabilityStock[]>> = await axios(options);
				if (result.data.err === ServerError.NO_ERROR) {
					const excelData: TraceabilityStockExcel[] = [];
					for (const currentStockInfo of result.data.payload) {
						excelData.push({
							"Cod Articol": currentStockInfo.ingredientId,
							"Denumire Articol": currentStockInfo.name,
							UM: currentStockInfo.um,
							"Stoc curent - gestiune principala": currentStockInfo.principalCurrentStock,
							"Stoc curent - gestiune productie": currentStockInfo.productionCurrentStock
						});
					}
					const ws = XLSX.utils.json_to_sheet(excelData);
					XLSX.utils.book_append_sheet(wb, ws, "Stock");
				} else {
					console.error(result.data.err);
				}
			}

			XLSX.writeFile(wb, `traceability_${this.filters.gasStation.name}_${moment().format("YYYY-MM-DD")}.xlsx`);
		}
	}
});
